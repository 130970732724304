import React from "react"
import styled from "styled-components"
import { SecondaryButton } from "@flow/buttons"
import { Colors } from "@flow/style"
import ErrorText from "./ErrorText"

/**
 * GenerateReportButton component
 * @param {object} props
 * @param {function} props.handleDownloadCaseReport
 * @param {boolean} props.isGeneratingReport
 * @param {boolean} props.isProcessing
 * @param {string} props.reportUrl
 * @param {string} props.generateReportError
 * @param {function} props.t
 * @returns {JSX.Element}
 */
const GenerateReportButton = ({
  handleDownloadCaseReport,
  isGeneratingReport,
  isProcessing,
  reportUrl,
  generateReportError,
  t,
}) => (
  <ReportWrapper>
    {!reportUrl && (
      <SecondaryButton
        onClick={handleDownloadCaseReport}
        disabled={isGeneratingReport || isProcessing}
      >
        {isGeneratingReport ? t(`generating-report`) : t(`generate-report`)}
      </SecondaryButton>
    )}

    {generateReportError && (
      <ErrorText error={t(`failed-to-generate-report`)} />
    )}

    {reportUrl && (
      <div style={{ display: "flex", justifyContent: "left" }}>
        <StyledLink href={reportUrl} target="_blank" rel="noreferrer">
          {t(`download-report`)}
        </StyledLink>
      </div>
    )}
  </ReportWrapper>
)

const ReportWrapper = styled.div`
  display: grid;
  justify-content: right;
  grid-row: 2;
  grid-column: 1;
  margin-top: 1em;
  margin-bottom: 1em;
`

const StyledLink = styled.a`
  color: ${Colors.Blue};
  text-decoration: none;
`

export default GenerateReportButton
