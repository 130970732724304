import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { withTranslation } from "react-i18next"
import Layout, { Context } from "../components/common/Layout"
import ReactForm from "../components/common/ReactForm"
import { PrimaryButton, SecondaryButton } from "@flow/buttons"
import FinancialCheckTab from "../components/performFinancialCheck/FinancialCheckTab"
import validate from "../components/performFinancialCheck/PayoutDetailsComponents.validationSchema"
import { Colors } from "@flow/style"
import { v4 as uuidv4 } from "uuid"
import { checkForDepotPayoutAuthority } from "../components/utils/checkForAuthority"
import { hasData } from "../util/returnValue"
import { FLOW_NS_DEV_OR_TEST } from "../components/ONLY_RENDER_IN_DEVELOPMENT"

/**
 * @param {InFlow.StateData} data
 * @param {InFlow.StateData} defaults
 * @param {{delivery: {id: string}}} variables
 * @param {{data: {deliveries: Object}}} flow
 *
 * @returns {InFlow.StateData}
 */
export const getInitialState = (data, defaults, variables, flow) => {
  /** @type {InFlow.Delivery} */
  const currentDelivery = flow?.data?.deliveries[variables.delivery.id]

  /** @type {InFlow.PerformFinancialCheck} */
  let currentStateData = currentDelivery?.currentPayout?.performFinancialCheck

  /** @type {InFlow.PayoutRequestReceived} */
  let payoutRequestTaskData =
    currentDelivery?.currentPayout?.payoutRequestReceived
  const { mustCheckSigners, payoutType } = payoutRequestTaskData

  // desse felta trengs ikkje frå utbet.anmodninga:
  // attachments
  // content
  // isCreditCheckOutdated
  // payoutAmount - blir henta inn lengre nede og satt på den initielle paymenten linje 77
  // submittedForm
  /**
   * @type {InFlow.StateData}
   */
  const dataFromPayoutRequestTask = {
    mustCheckSigners,
    payoutType,
  }

  // This is to reset after coming back from "Be om mer dokumentasjon"
  if (currentStateData) {
    currentStateData.commentToCustomer = null
    currentStateData.payoutDecision = null
  }

  return data || currentStateData || dataFromPayoutRequestTask || defaults
}

const PerformFinancialCheck = ({
  task,
  flow,
  t,
  schema,
  save, // Function for saving the task, includes updating the flow and closing the task screen.
  complete,
  onSave, // Function for saving the task, does NOT include updating the flow and closing the task screen
  updateCase,
  user,
}) => {
  const { data, defaults, variables } = task
  /** @type {InFlow.StateData} */
  const initialState = getInitialState(
    data?.stateData,
    defaults,
    variables,
    flow
  )
  const deliveryId = task?.variables.delivery.id
  /** @type {InFlow.Delivery} */
  const currentDelivery = flow?.data?.deliveries[deliveryId]
  const { payoutRequestReceived, commitment } =
    currentDelivery.currentPayout || {}
  const customerAccount = flow?.data.raw?.accountMaster?.customerAccount || {}
  /** @type {string | undefined} */
  const applicantName = customerAccount?.name
  initialState.payments = initialState.payments || [
    {
      customerAccountNumber: payoutRequestReceived?.customerAccountNumber,
      payoutName: applicantName,
      id: uuidv4(),
      payoutOrgNumber: flow.data.raw.accountMaster.customerAccount.publicId,
      isPerson:
        flow.data.raw.accountMaster.customerAccount.accountType === "Person",
      payoutApplicantOrThirdPerson: "applicant",
      payoutAmount: payoutRequestReceived?.payoutAmount,
    },
  ]

  const isPerformFinancialCheck = true

  const [stateData, setStateData] = useState(initialState)
  const [formData, setFormData] = useState(data?.formData || {})
  const [isProcessing, setProcessing] = useState(false)
  const [validationError, setValidationError] = useState({})
  const { applicationSummary, insightComponentData } = task.context
  initialState.totalPayoutAmount = stateData.totalPayoutAmount || 0
  initialState.remainingAmount = stateData.remainingAmount || 0

  const totalPayoutAmount = Number(
    currentDelivery.productClass === "Guarantee"
      ? stateData.guarantee?.guaranteeAmount ?? stateData.totalPayoutAmount
      : stateData.totalPayoutAmount
  )

  const [allowedToDecide, setAllowedToDecide] = useState(undefined)
  const [allowedToDecideReason, setAllowedToDecideReason] = useState("")
  const [errorMessages, setErrorMessages] = useState()

  useEffect(() => {
    const fetchData = async () => {
      const data = await checkForDepotPayoutAuthority(
        user,
        task.taskType,
        flow.flowId,
        flow.data.application.category
      )
      if (FLOW_NS_DEV_OR_TEST()) {
        setAllowedToDecide(true)
        setAllowedToDecideReason("Dev or test")
      } else {
        setAllowedToDecide(data.allowed)
        setAllowedToDecideReason(data.reason)
      }
    }

    fetchData()
  }, [user])

  // Live validering for hver gang state endrer seg
  // Dette er kun nødvendig fordi at errors er state,
  // bortsett fra checklistError trenger vi egentlig ikke
  // hverken state eller useEffect her.
  useEffect(() => {
    performValidations(stateData, formData)
  }, [stateData, formData])

  // Setter kommentar fra state til formdata
  useEffect(() => {
    setFormData((prev) => ({
      ...prev,
      comment: stateData.comment,
    }))
  }, [stateData.comment])

  // Valideringssjekk
  const performValidations = (stateData, formData) => {
    setErrorMessages((previousErrorMessages) => {
      let newErrorState = { ...previousErrorMessages }

      const payments = stateData.payments.filter((x) => !!x) || []
      if (payments.length === 0) {
        newErrorState.hasMissingPayoutFields =
          "must-have-at-least-one-payout-and-fields-must-be-set"
      } else {
        const payoutErrors =
          payments.filter(
            (payment) =>
              Object.keys(payment).filter((x) => {
                const value = payment[x]
                if (x === "isPerson" && value !== null) return false
                if (x === "payoutAmount") {
                  const isValidPayoutAmount =
                    typeof value === "number" && isFinite(value)

                  // Value 0 is allowed for finalPayout
                  if (stateData.payoutType === "finalPayout") {
                    return !isValidPayoutAmount
                  }
                  // But not otherwise
                  return !isValidPayoutAmount || value === 0
                }
                return !hasData(value) || value === 0
              }).length > 0
          ).length > 0

        if (payoutErrors) {
          newErrorState.hasMissingPayoutFields =
            "must-have-at-least-one-payout-and-fields-must-be-set"
        } else {
          // We have multiple payments and we have filled out all data
          delete newErrorState.hasMissingPayoutFields
        }
      }

      // Sjekk dersom total beløp overstiger tilgjengelig beløp
      if (totalPayoutAmount > commitment.accountByAccountName.availableAmount) {
        newErrorState.availableAmountFail = "available-amount-fail"
      } else {
        delete newErrorState.availableAmountFail
      }

      // Egen feilmelding til saksbehandler dersom den mangler kommentar
      if (
        formData.payoutDecision === "MOREINFO" &&
        formData?.commentToCustomer === undefined
      ) {
        newErrorState.requiredComment = "comment-to-customer-required"
      } else {
        delete newErrorState.requiredComment
      }

      if (currentDelivery.productClass === "Guarantee") {
        const requiredFields = [
          "creditAmount",
          "guaranteeAmount",
          "guaranteePercent",
          "bankAddress",
          "loanAgreementNumber",
        ]

        if (
          !stateData.guarantee ||
          requiredFields.some((field) => !stateData.guarantee[field])
        ) {
          newErrorState.guarantee = "guarantee-required"
        } else {
          delete newErrorState.guarantee
        }
      } else {
        delete newErrorState.guarantee
      }

      // This will erase other validations, which is bad. But it's ok because
      // the only other validation is for the schema for the checklist, and
      // this is validated on attempted complete, so they'll get it later.
      return newErrorState
    })
  }

  stateData.whoDidIt = user?.profile?.email
  const handlePartialSave = (newData) => {
    setProcessing(true)

    onSave(
      task.taskId,
      {
        ...initialState,
        customerAccountNumber: newData.customerAccountNumber,
        karErrorMessage: newData.karErrorMessage,
      },
      () => {
        updateCase()
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  /**
   * @typedef {Object} SaveData
   * @property {InFlow.StateData} stateData
   * @property {Object} formData
   */

  const handleSave = () => {
    /** @type {SaveData} */
    const data = { stateData, formData }
    setProcessing(true)
    save(
      data,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }
  const handleComplete = async () => {
    const values = { ...stateData, ...formData }
    /** @type {InFlow.PayoutCompleteData} */
    const completeData = formatValues(values)
    setProcessing(true)

    if (
      completeData.payoutDecision !== "REJECT" &&
      completeData.payoutDecision !== "MOREINFO"
    ) {
      const isValid = await validateSchema(completeData, t)

      // If the schema is not valid then the checklist is not filled.
      if (!isValid) {
        setErrorMessages((prevState) => ({
          ...prevState,
          checklistError: "fill-checklist",
        }))

        // Don't complete
        setProcessing(false)
        return
      } else {
        // Otherwise, remove the checklist error.
        setErrorMessages((prevState) => {
          delete prevState?.checklistError
          return { ...prevState }
        })
      }

      // Incase we removed checklistError, we won't have the updated state here.
      // Because of this, errorMessages would still contain "checklistError", despite
      // it actually being removed. If we still actually have checklistError, we would
      // have reached the first statement and would already have returned, so that won't
      // ever happen here, thus we can filter it out from errorMessages.
      const nonChecklistErrors = Object.keys(errorMessages ?? {}).filter(
        (key) => key !== "checklistError"
      )
      if (errorMessages && nonChecklistErrors.length > 0) {
        setProcessing(false)
        return
      }

      /*
        Mulig svar får kall til kar-sjekk:
        "01": "Konto finnes og eies av angitt kunde.",
        "02": "Konto er omnummerert og ny konto eies av angitt kunde.",
        "03": "Konto finnes, men eies ikke av angitt kunde.",
        "04": "Konto finnes ikke.",
        "05": "Angitt konto er omnummerert, men ny konto finnes ikke.",
        "06": "Angitt konto er omnummerert. Ny konto finnes, men eies ikke av angitt kunde.",
        "09": "Ukjent registernummer i angitt konto.",
        "10": "Angitt konto er ikke CDV-gyldig.",
        "11": "Angitt kundenummer er ikke gyldig.",
        "13": "Bankens data er sperret for oppslag fra andre banker.",
        "15": "Konto er omnummerert til ny konto, og ny konto eies av kunden med ugyldig kundenummer."
        Vi bør på sikt (etter avtale med IN) ikkje la dei fullføre oppgaven dersom man har fått ein av desse svara tilbake:
        04 09 10 15
        Då må me sjekke under på feltet resultCode i responsen frå KAR
        stateData.resultCode !== ein av kodane over
      */
    }

    complete(
      completeData,
      () => {
        setProcessing(false)
      },
      () => {
        console.error("Could not complete task")
        setProcessing(false)
      }
    )
  }

  const formatValues = (values) => {
    // remove everything except data for the option chosen to avoid data filled in for other options to bleed over

    if (values?.payoutDecision === "OK") {
      delete values?.commentToCustomer
    }
    if (values?.payoutDecision === "REJECT") {
      delete values?.commentToCustomer
    }
    return values
  }

  const validateSchema = async (data, t) => {
    const validation = await validate(data, t)
    setValidationError(validation.errors)
    return validation?.isValidSchema
  }

  const notificationMessage =
    stateData.payoutType === "finalPayout" && totalPayoutAmount === 0

  return (
    <Layout forceHeight>
      <MainContent>
        <FinancialCheckTab
          deliveryId={deliveryId}
          flow={flow}
          t={t}
          stateData={stateData}
          partialSave={handlePartialSave}
          setStateData={setStateData}
          validationError={validationError}
          task={task}
          commonData={insightComponentData?.commonData}
          isPerformFinancialCheck={isPerformFinancialCheck}
        />
      </MainContent>
      <Context flow={flow} context={applicationSummary}>
        {!allowedToDecide && (
          <p style={{ color: Colors.Ferrari, paddingBottom: "5px" }}>
            {t(allowedToDecideReason)}
          </p>
        )}
        <StyledForm>
          <ReactForm
            schema={schema}
            formData={formData}
            disabled={isProcessing || !allowedToDecide}
            onChange={(values) => {
              setFormData({
                ...formData,
                payoutDecision: values.payoutDecision,
                commentToCustomer: values.commentToCustomer,
              })
            }}
          >
            <></>
          </ReactForm>
        </StyledForm>
        {errorMessages &&
          Object.values(errorMessages).map((error) => (
            <ErrorDiv>{t(error)}</ErrorDiv>
          ))}
        {notificationMessage && (
          <ErrorDiv> {t("final-payout-notification")}</ErrorDiv>
        )}
        <ButtonContainer>
          <PrimaryButton
            type="submit"
            isLoading={isProcessing}
            disabled={isProcessing || !allowedToDecide}
            onClick={() => handleComplete()}
          >
            {t("complete")}
          </PrimaryButton>
          <SecondaryButton
            type="button"
            disabled={isProcessing}
            onClick={() => handleSave()}
          >
            {t("save")}
          </SecondaryButton>
        </ButtonContainer>
      </Context>
    </Layout>
  )
}
export default withTranslation()(PerformFinancialCheck)
const ErrorDiv = styled.div`
  color: ${Colors.Ferrari};
`
const StyledForm = styled.div`
  #root_rejectionText {
    height: 200px;
  }
`
const MainContent = styled.div`
  height: 100%;
  flex: 1;
  border-right: 1px solid #e4e2e2;
  overflow-y: scroll;
`

const ButtonContainer = styled.div`
  display: flex;
  margin-top: 1em;
  height: 30px;
  gap: 10px;
  justify-content: flex-start;
`
